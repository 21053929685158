/* Footer.css */
.footer {
  background-color: #232f3e; /* AWS Dark Blue */
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  margin-top: 100px;
}

.footer-content p {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.footer-links {
  list-style: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.footer-links li {
  margin: 0 15px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
  color: #ff9900; /* AWS Orange for hover */
}
