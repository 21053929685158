.material-cards-container {
  display: flex;
  justify-content: space-between; /* Adjust the spacing between cards */
  position: relative;
  top: -100px;
  margin: 0 auto;
  max-width: 1300px; /* Increase the maximum width */
  flex-wrap: wrap; /* Allows cards to wrap on smaller screens */
}

.material-card {
  width: 420px; /* Increase the width of the cards */
  margin-bottom: 10px; /* Reduce the margin between cards */
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.material-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
  border-radius: 8px;
  z-index: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.material-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 12px 24px rgba(0,0,0,0.3);
}

.material-card:hover::before {
  opacity: 1;
}

.card-content {
  padding: 20px;
  position: relative;
  z-index: 1;
}

.card-image-placeholder, .card-image {
  width: 100%;
  height: 150px;
  background-color: #E0E0E0;
  margin-top: 20px;
  border-radius: 4px;
  object-fit: cover;
}

.card-image {
  height: auto;
}

.material-card h3 {
  position: relative;
  color: #009193;
  font-weight: 600;
  text-decoration: underline;
}

.material-card p {
  color: #000;
}

.card-image:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
