/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Front of other elements */
}

/* Modal Box */
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="email"],
input[type="text"] {
  width: 300px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button Styles */
button.waitlist-btn,
button[type="submit"] {
  padding: 10px 20px;
  background-color: #3D405B;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

button.waitlist-btn:hover,
button[type="submit"]:hover {
  background-color: #0056b3;
}

.modal form label {
  text-align: left !important; /* Aligns label text to the left */
  display: block; /* Ensures label takes up the full width, allowing for text alignment */
  margin-bottom: 10px; /* Optional: adds some space below each label */
}
