/* App.css */
/* Centralized container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Specific styling for each section for color differentiation */
.product-banner {
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 50px;
  overflow: hidden;
}

.video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.product-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* Constrain to left half */
  margin-left: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align text to the left */
  color: #fff;
  text-align: left; /* Align text to the left */
  padding: 0 20px; /* Add padding for better readability */
}

.product-content h1 {
  font-size: 2em;
  font-weight: lighter;
}

.product-content p {
  font-size: 1.2em;
  font-weight: lighter;
}
