.nav-container {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
}

.brand-title {
  color: black;
  font-size: 24px;
  margin-right: auto; /* Align brand title to the far left */
}

.nav-links {
  list-style: none;
  display: flex;
  flex-direction: row; /* Change to row */
  justify-content: flex-start; /* Align to the left */
  align-items: center;
  padding: 0; /* Remove margin */
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease-in-out;
}

.nav-links a:hover {
  color: #ff9900;
}
